// initilize and distribute shared first
import { initializeServices, CONST } from "./shared";
const shared = initializeServices({
  helper: { envMode: import.meta.env.MODE },
  log: { envMode: import.meta.env.MODE },
  api: {
    baseUrl: import.meta.env.__API,
  },
  theme: { vars: CONST.THEME_VARS },
});
console.log = shared.log.dev.info;
console.error = shared.log.dev.error;
console.trace = shared.log.dev.trace;
console.warn = shared.log.dev.warn;

//? to confirm that all loggers across all envs are working properly by sending initilizing message
shared.log.all.enableAll();
shared.log.dev.info("Shared initialized!");
shared.log.stage.info("Shared initialized!");
shared.log.prod.info("Shared initialized!");

// shared exports
export const log: typeof shared.log = shared.log;
export const api: typeof shared.api = shared.api;
export const helper: typeof shared.helper = shared.helper;
// export const state: typeof shared.state = shared.state;
export const theme: typeof shared.theme = shared.theme;
export {
  cookies,
  CONST as SHARED_CONST,
  model as SHARED_MODEL,
  validate as SHARED_VALIDATIONS,
  util as SHARED_UTILS,
  state,
} from "./shared";
